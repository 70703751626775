<template>
  <div
    v-show="is_show"
    id="layer_dvSelect"
    class="modal active"
    role="dialog"
    transition="modal"
  >
    <div class="modal_dialog">
      <div class="modal_content">
        <header class="modal_title">
          <h2>{{ getLang('폴더 선택') + ` (${getLang(title)})` }}</h2>
          <a
            href="javascript:void(0)"
            class="btn_modal_close"
            @click="close"
          >
            <span class="ir">레이어팝업 닫기</span>
          </a>
        </header>
        <div class="modal_body">
          <div class="innerscroll">
            <div class="categ_list">
              <ul>
                <li
                  v-for="el in favInfo"
                  :key="el.fav_uid"
                >
                  <a
                    href="javascript:void(0)"
                    :style="(arrAddedFav || []).filter(fav => fav.fav_uid == el.fav_uid).length ? 'color:#f00; font-weight:bold' : ''"
                    @click="onClickFavFolder(el.fav_uid)"
                  >
                    {{ (el.depth ? '&nbsp;'.repeat((el.depth - 1) * 5) + '└&nbsp; ' : '') + getLang(el.fav_name) }}
                  </a>
                </li>
              </ul>
              <div
                class="bottom_dim"
                @click="close"
              >
                <img src="/img/img/box_popup_grsdation_list.png">
              </div>
            </div>
          </div>
        </div>
        <footer class="modal_btns">
          <ul>
            <li>
              <button
                class="btn_modal"
                @click="confirm"
              >
                {{ getLang('취소') }}
              </button>
            </li>
          </ul>
        </footer>
        <div
          v-if="!arrAddedFav"
          style="margin:5px auto; padding:5px 10px; text-align:right; color:#aaa; background-color:#000a"
        >
          {{ getLang('다중 선택 모드에서는 폴더명에 즐겨찾기 여부가 표시되지 않습니다') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    arrAddedFav: {
      type: Array,
      required: false,
      default: null,
    },
  },
  data () {
    return {
      is_show: false,
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang',
      favInfo: 'getFavInfo',
    }),
  },
  beforeDestroy () {
    this.close();
  },
  mounted () {
  },
  methods: {
    evEscape (ev) {
      if (ev.key == 'Escape') this.close();
    },
    show () {
      this.is_show = true;
      window.addEventListener('keyup', this.evEscape);
    },
    close () {
      this.is_show = false;
      window.removeEventListener('keyup', this.evEscape);
    },
    confirm () {
      this.close();
    },
    onClickFavFolder (fid) {
      this.close();
      this.$emit('chg-fav', fid);
    }
  },
};
</script>

<style scoped>
  .categ_list li a {
    transition: all 0.3s ease-in;
  }
  .categ_list li a:hover {
    background-color: #ddd;
    transition: all 0s;
  }
</style>
