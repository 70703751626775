<template>
  <div id="cont-view">
    <TitleBar
      :top-title="title"
      :top-left="'back'"
      :ar="ar_yn"
      @on-click-ar="onClickAR()"
    />
    <FavFolderPopup
      ref="FavFolderPopup"
      :title="'즐겨찾기 토글'"
      :arr-added-fav="arrAddedFav"
      @chg-fav="chgFav"
    />
    <table style="height:calc(100% - 54px)">
      <tr>
        <td>
          <div class="conts_wrap">
            <div class="viewer_wrap cont_body bg_fff">
              <div
                id="viewer"
                style="width:100%; height:100%"
              />
              <canvas
                id="canvas-qr"
                style="position:absolute; top:0; left:0; z-index:99; pointer-events:none"
              />
            </div>
            <div class="descr_wrap">
              <transition name="fade">
                <div
                  v-if="!descrReadonly"
                  style="position:absolute; top:2px; right:2px; z-index:1"
                >
                  <input
                    type="button"
                    :value="getLang('원본')"
                    @click="originDescr"
                  >
                  <input
                    type="button"
                    :value="getLang('저장')"
                    @click="saveDescr"
                  >
                  <input
                    type="button"
                    :value="getLang('취소')"
                    @click="cancelDescr"
                  >
                </div>
              </transition>
              <textarea
                ref="descr"
                v-model="contsInfo.descrForm"
                class="descr_wrap_textarea"
                :readonly="descrReadonly"
                :style="{
                  'border': `1px solid ${descrReadonly? '#cdd1d6' : 'red'}`,
                  'background-color':`${descrReadonly? 'unset' : '#fff'}`
                }"
                @click="editingDescr"
              />
            </div>
          </div>
        </td>
      </tr>
      <tr style="height:51px">
        <td>
          <div class="btns_view">
            <ul class="col1">
              <li>
                <button
                  class="btn_w"
                  :class="{'active': arrAddedFav.length}"
                  @click="onClickFav"
                >
                  <i
                    class="ico_favor"
                    style="filter:unset"
                  /> {{ getLang('즐겨찾기') }}
                </button>
              </li>
            </ul>
          </div>
        </td>
      </tr>
    </table>
    <div
      id="ar"
      style="position:absolute; bottom:30px; margin-left:calc( 50vw - 25px ); width:50px"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from 'vuex';
import TitleBar from '@/components/navi/TitleBar.vue';
import FavFolderPopup from '@/components/popup/FavFolderPopup.vue';
import axios from 'axios';
import qrCode from 'qrcode';

export default {
  metaInfo () {
    return {
      title: this.title,
    };
  },
  components: {
    TitleBar,
    FavFolderPopup,
  },
  data () {
    return {
      title: '',
      uu: parseInt(this.$route.query.uu) || 0,
      uid: parseInt(this.$route.query.uid) || 0,
      arrAddedFav: [],
      detailData: {},
      voices: [],
      contsInfo: {},
      descrReadonly: true,
      ar_yn: false,
    };
  },
  computed: {
    ...mapGetters({
      getLang: 'getLang',
      favInfo: 'getFavInfo',
    }),
    uuid () {
      return {
        uu: this.uu,
        uid: this.uid,
      };
    },
  },
  beforeDestroy () {
    $('#cont-view').off();
    clearInterval(this.intervalIdx);

    if (this.viewer) {
      this.viewer.clean();
      this.viewer = null;
    }
  },
  async mounted () {
    await this.loadBasic();
    this.ar_yn = this.$store.state.ar_yn == 'Y';

    this.contsInfo = await this.loadContsInfo(this.uuid);
    this.arrAddedFav = await this.loadAddedFav(this.uuid);

    this.title = this.contsInfo.nm;
    this.resetDescr();

    const opt = {
      host: '3dbank',
      srchost: '//3dbank.xyz/asia3d/3drealplus/5.0.1/release/',
      // localpath: undefined,
      // thumbnail: undefined,
      // token_uuid: undefined,
      // callBack: this.callBack,

      rotateSpeed: 1,
      volume: 0,
      toolbar: 2,

      major: this.contsInfo.major || undefined,
      minor: this.contsInfo.minor || undefined,
      env_light_tp: this.contsInfo.envLightTP,
      ambientIntensity: this.contsInfo.ambientIntensity,
      directIntensity: this.contsInfo.directIntensity,
    };
    console.log('#VUE. viewer options', JSON.parse(JSON.stringify(opt)));

    window.viewer = this.viewer = new window.THREEDBANK.viewer('viewer', opt);
    this.viewer.open(this.uid, {
      autoplay: false,
      type: this.contsInfo.type,
    }).then(() => {
      qrCode.toCanvas(document.getElementById('canvas-qr'), `https://www.3dbank.xyz/contents/view_popup.jsp?idx=${this.uid}`);
    });
  },
  methods: {
    ...mapMutations({
      clearFavList: 'clearFavList',
    }),
    ...mapActions({
      loadBasic: 'loadBasic',
      loadContsInfo: 'loadContsInfo',
      loadAddedFav: 'loadAddedFav',
      loadFavInfo: 'loadFavInfo',
      chgShowList: 'chgShowList',
    }),
    onClickAR () {
      const inform = window.viewer.arrInform[0];
      inform.thumb = inform.thumb.replace('http://', 'https://').replace(':13579', ':13578');
      const informStr = encodeURIComponent(JSON.stringify(inform));

      if (this.isIOS()) {
        location.href = `/embed_ar.html?inform=${informStr}`;
      } else if (location.host == 'service.3dbank.xyz' && location.protocol != 'https:') {
        location.href = `https://${location.host}/Content_AR?inform=${informStr}`;
      } else {
        this.$router.push({
          path: '/Content_AR',
          query: { inform: informStr }
        });
      }
    },
    onClickFav () {
      if (this.favInfo.filter(el => el.parent_uid).length) {
        this.$refs.FavFolderPopup.show();
      } else {
        const rootFid = (this.favInfo.filter(el => !el.parent_uid)[0] || {}).fav_uid || 0;
        this.chgFav(rootFid);
      }
    },
    async chgFav (_fid) {
      const isAdd = !_fid || !this.arrAddedFav.filter(el => el.fav_uid == _fid).length;
      const rs = await axios.post(`${this.$store.state.apiHost}/api/addFav`, {
        fid: _fid,
        arr_uuid: [`${this.uu}-${this.uid}`],
        isAdd: isAdd,
      });

      let msg;
      if (rs.data.result == 'OK') {
        this.chgShowList({ isAdd: isAdd, fid: rs.data.fid, arrConts: [`${this.uu}-${this.uid}`] });
        if (isAdd) this.loadFavInfo(true);
      } else if (rs.data.result == 'NOT EXIST') {
        msg = this.getLang('선택된 폴더를 찾을 수 없습니다.');
      } else {
        msg = this.getLang('시스템 장애가 발생하였습니다.');
      }
      if (msg) this.showAlert(this.getLang('즐겨찾기'), msg);

      this.arrAddedFav = await this.loadAddedFav(this.uuid);
    },
    getDescr () {
      return this.encodeDescr(this.contsInfo.descr);
    },
    resetDescr () {
      this.contsInfo.descrForm = this.getDescr();
      this.$forceUpdate();
    },
    encodeDescr (_descr) {
      return _descr.replace(/<br>/ig, '\n');
    },
    decodeDescr (_descr) {
      return _descr.replace(/\r/g, '').replace(/\n/g, '<br>');
    },
    editingDescr () {
      if (this.uu && this.descrReadonly) {
        this.effDescr().then(() => {
          this.descrReadonly = false;
        });
      }
    },
    originDescr () {
      this.contsInfo.descrForm = this.encodeDescr(this.contsInfo.descr_3dbank);
      this.$forceUpdate();
    },
    saveDescr () {
      if (this.contsInfo.descrForm == this.getDescr()) {
        this.descrReadonly = true;
        this.effDescr();
      } else {
        const descr = this.decodeDescr(this.contsInfo.descrForm);

        axios.post(`${this.$store.state.apiHost}/api/saveDescr`, {
          uu: this.uu,
          uid: this.uid,
          descr: descr,
        }).then(res => {
          this.descrReadonly = true;
          this.effDescr();
          this.contsInfo.descr = descr;
        }).catch(e => {
          this.showAlert(this.title, '오류가 발생하였습니다.');
        });
      }
    },
    cancelDescr () {
      this.descrReadonly = true;
      this.effDescr().then(() => {
        this.resetDescr();
      });
    },
    effDescr () {
      return new Promise((resolve) => {
        $(this.$refs.descr).stop().fadeOut(200, function () {
          $(this).fadeIn(100, resolve);
        });
      });
    },
  }
};
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .2s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
</style>
