<template>
  <div class="top_header">
    <div class="title">
      {{ topTitle }}
    </div>
    <div class="left">
      <a
        v-if="topLeft=='back'"
        href="javascript:void(0)"
        class="btn_top_back"
        title="이전"
        @click.stop="onBackClick"
      />
      <a
        v-if="topLeft=='setting'"
        href="javascript:void(0)"
        class="btn_top_setting"
        title="설정"
        @click.stop="onClickSetting"
      />
    </div>
    <div class="right">
      <a
        v-if="ar && canAR"
        href="javascript:void(0)"
        class="btn_top_ar"
        title="AR"
        @click.stop="onClickAR"
      />
      <a
        v-if="addFolder"
        href="javascript:void(0)"
        class="btn_top_addfolder"
        title="폴더 생성"
        @click.stop="onClickAddFolder"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    topTitle: {
      type: String,
      required: true,
    },
    topLeft: {
      type: String,
      required: false,
      default: 'back',
    },
    ar: {
      type: Boolean,
      required: false,
      default: false,
    },
    addFolder: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data () {
    return {
      // canAR: true
      canAR: (
        window.location.protocol == 'https:' ||
        window.location.href.indexOf('//service.3dbank.xyz') > 0
      )
    };
  },
  mounted () {
  },
  methods: {
    onBackClick: function () {
      this.$router.go(-1);
    },
    onClickSetting () {
      this.$router.push({ path: '/Setting' });
    },
    onClickAR () {
      this.$emit('on-click-ar');
    },
    onClickAddFolder () {
      this.$emit('on-click-add-folder');
    },
  }
};
</script>

<style scoped>
</style>
